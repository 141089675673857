.footer-container {
    padding: 20px 25px 80px;
    font-size: 12px;
    background-color: $dark-navy;
    color: $white;
}

.footer-text-container {
    min-width: 900px;
    max-width: 1440px;
    margin: auto;
    flex-direction: row;
    display: flex;
}

.footer-blue-text {
    color: $motili-light-blue;
}

.footer-left-section {
    flex: 1;
}

.footer-middle-section {
    flex: 1;
    text-align: center;
}

.footer-right-section {
    flex: 1;
    text-align: right;
}

.padding-right {
    padding-right: 20px;
}
