//**************************************************//
//* BUTTON STYLING                                 *//
//**************************************************//

//***** BUTTON COLORS ******\\
.motili-normal-btn-color {
    // normal un-filled button
    @include button-variant($motili-light-blue, #fff, $light-gray);
    &:hover {
        color: #fff;
        background-color: $motili-light-blue;
        border-color: $motili-light-blue;
    }
    &[disabled]:hover {
        color: $motili-light-blue;
        background-color: #fff;
        border-color: #e9e9e9;
    }
    &[disabled] {
        color: $motili-light-blue;
        background-color: #fff;
        border-color: #e9e9e9;
    }
    &:focus {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
}

.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #2ab0e3;
    border-color: transparent;
}
.motili-blue-outline-btn-color {
    // blue un-filled button
    @include button-variant($motili-light-blue, #fff, $motili-light-blue);
    &:hover {
        color: #fff;
        background-color: $motili-light-blue;
        border-color: $motili-light-blue;
    }
    &:focus {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
    &:disabled {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
}

.motili-primary-btn-color {
    // primary filled button
    @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    &:hover {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
    &:focus {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
    &:disabled {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
}

.motili-navy-btn-color {
    // navy filled button
    @include button-variant(#fff, $navy, $navy);
    &:hover {
        @include button-variant(#fff, $navy, $navy);
    }
    &:focus {
        @include button-variant(#fff, $navy, $navy);
    }
    &:disabled {
        @include button-variant(#fff, $navy, $navy);
    }
}

.motili-action-btn-color {
    // blue un-filled button
    @include button-variant($motili-light-blue, #fff, $light-gray);
    &:hover {
        @include button-variant($motili-light-blue, #fff, $light-gray);
    }
    &:focus {
        @include button-variant($motili-light-blue, #fff, $light-gray);
    }
    &:disabled {
        @include button-variant($motili-light-blue, #fff, $light-gray);
    }
}

.motili-navy-outline-btn-color {
    // blue un-filled button
    @include button-variant($navy, #fff, $navy);
    &:hover {
        color: #fff;
        background-color: $navy;
        border-color: $navy;
    }
    &:focus {
        @include button-variant(#fff, $navy, $navy);
    }
    &:disabled {
        @include button-variant(#fff, $navy, $navy);
    }
}

//***** BUTTON SIZES ******\\
.motili-sm-btn-size {
    // small button
    min-width: 100px;
    height: 30px;
    font-size: 12px;
}

.motili-md-btn-size {
    // medium button
    min-width: 140px;
    height: 35px;
    font-size: 14px;
}

.motili-lg-btn-size {
    // large button
    min-width: 150px;
    height: 40px;
    font-size: 14px;
}

.motili-btn {
    @extend .motili-normal-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-sm {
    @extend .motili-normal-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-lg {
    @extend .motili-normal-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-primary {
    @extend .motili-primary-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-primary-sm {
    @extend .motili-primary-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-primary-lg {
    @extend .motili-primary-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-primary-block {
    @extend .motili-primary-btn-color;
    height: 40px;
    width: 100%;
    font-size: 14px;
}

.motili-btn-navy {
    @extend .motili-navy-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-navy-sm {
    @extend .motili-navy-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-navy-lg {
    @extend .motili-navy-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-action {
    @extend .motili-action-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-action-sm {
    @extend .motili-action-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-action-lg {
    @extend .motili-action-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-navy-outline {
    @extend .motili-navy-outline-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-navy-outline-sm {
    @extend .motili-navy-outline-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-navy-outline-lg {
    @extend .motili-navy-outline-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-blue-outline {
    @extend .motili-blue-outline-btn-color;
    @extend .motili-md-btn-size;
}

.motili-btn-blue-outline-sm {
    @extend .motili-blue-outline-btn-color;
    @extend .motili-sm-btn-size;
}

.motili-btn-blue-outline-lg {
    @extend .motili-blue-outline-btn-color;
    @extend .motili-lg-btn-size;
}

.motili-btn-blue-outline-block {
    @extend .motili-blue-outline-btn-color;
    height: 40px;
    width: 100%;
    font-size: 14px;
}

// TODO: all the classes below should be able to be removed, I don't think they are being used
.motili-btn-large-grey {
    @extend .motili-btn;
    @include button-variant($motili-light-blue, #fff, $light-gray);
    width: 320px;
    height: 50px;
    font-size: 19px;
    &:hover {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
    &:focus {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
}

.motili-btn-large-blue {
    @extend .motili-btn;
    @include button-variant($motili-light-blue, #fff, $motili-light-blue);
    width: 320px;
    height: 50px;
    font-size: 19px;
    &:hover {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
    &:focus {
        @include button-variant(#fff, $motili-light-blue, $motili-light-blue);
    }
}

.motili-btn-accept-job {
    @extend .motili-btn;
    @include button-variant(#fff, $brand-success, $brand-success);
    height: 50px;
    width: 170px;
    font-size: 20px;
}

.motili-btn-decline-job {
    @extend .motili-btn;
    @include button-variant(#fff, $brand-danger, $brand-danger);
    height: 50px;
    width: 170px;
    font-size: 20px;
}

.motili-btn-toggle {
    @extend .motili-btn;
    @include button-variant($navy, #fff, $navy);
    width: 70px;
    font-weight: bold;
    &:hover {
        @include button-variant(#fff, $motili-light-blue, $navy);
    }
}

.motili-btn-save {
    height: 45px;
    margin-bottom: 20px;
    font-size: 18px;
}

.motili-cancel-btn {
    @extend .motili-btn;
    @include button-variant($motili-light-blue, #fff, $light-gray);
    height: 45px;
    font-size: 18px;
    &:hover {
        @include button-variant($motili-light-blue, #fff, $light-gray);
    }
    &:focus {
        @include button-variant($motili-light-blue, $light-gray, $light-gray);
    }
    margin-bottom: 20px;
    margin-left: 15px;
    padding-top: 10px;
}

.add-btn {
    width: 70px;
    height: 45px;
    font-size: 18px;
    padding-top: 10px;
}

.input-group {
    width: auto;
}

a:not([href]):not([class]) {
    color: $motili-light-blue;
}

.btn-primary:not(:disabled):not(.disabled):active {
    color: $motili-light-blue;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #cacaca;
}

.login-form {
    .btn-primary:disabled {
        background-color: #337ab7;
        border-color: #337ab7;
    }
}
